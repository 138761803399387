<template>
    <div
      id="modal-FCM"
      class="modal"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <load-spinner v-if="isLoadSpinner"/>
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">FCM History</h5>
          </div>
            <div class="modal-body"  v-if="detailFCM.id">
                  <div class="form-group row mb-2">
                      <label class="col-lg-3 col-form-label font-weight-bold">ID :</label>
                      <label class="col-lg-7 col-form-label">{{detailFCM.id}}</label>
                  </div>
                  <div class="form-group row mb-2">
                      <label class="col-lg-3 col-form-label font-weight-bold">Created By :</label>
                      <label class="col-lg-7 col-form-label">{{detailFCM.createdBy }}</label>
                  </div>
                  <div class="form-group row mb-2">
                      <label class="col-lg-3 col-form-label font-weight-bold">Created Date :</label>
                      <label class="col-lg-7 col-form-label">{{detailFCM.createdDate | formatDate}}</label>
                  </div>
                  <div class="form-group row mb-2">
                      <label class="col-lg-3 col-form-label font-weight-bold">Title :</label>
                      <label class="col-lg-7 col-form-label">{{detailFCM.title}}</label>
                  </div>
                  <div class="form-group row mb-2">
                      <label class="col-lg-3 col-form-label font-weight-bold">Message :</label>
                      <label class="col-lg-7 col-form-label">{{detailFCM.message}}</label>
                  </div>
                  <div class="form-group row mb-2">
                      <label class="col-lg-3 col-form-label font-weight-bold">Recipient :</label>
                      <div class="form-group col-lg-9">
                          <textarea class="form-control" rows="6" disabled :value="detailFCM.recipient"></textarea>
                      </div>
                  </div>
                  <div class="form-group row mb-2">
                      <label class="col-lg-3 col-form-label font-weight-bold">Recipient :</label>
                      <div class="form-group col-lg-9">
                          <textarea class="form-control" rows="6" disabled :value="detailFCM.content"></textarea>
                      </div>
                  </div>
                  <div class="form-group row mb-2">
                      <label class="col-lg-3 col-form-label font-weight-bold">Status Pengiriman :</label>
                      <label class="col-lg-7 col-form-label">{{detailFCM.sendStatus}}</label>
                  </div>
          
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-clean btn-bold btn-upper btn-font-md"
                @click="clearData()"
              >Close</button>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  import { mapState, mapMutations, mapActions } from 'vuex';
  import LoadSpinner from "./../../_general/LoadSpinner";
  
  export default {
  components: {
      LoadSpinner
    },
    props: {
      idFcm: {
        type: Number,
        default: 0
      }
    },
    computed: {
      ...mapState('NotifikasiStore', {
        detailFCM: state => state.detailFCM,
        isLoadSpinner: state => state.isLoadSpinner
      }),
    },
    filters: {
        formatDate: function(value) {
            return moment(value).format("DD-MMM-YYYY HH:mm:ss");
        }
    },
    methods: {
     ...mapActions({
        getByIdFCM: 'NotifikasiStore/GET_HISTORY_DETAIL_FCM'
      }),
        ...mapMutations({
        changeShowModal: 'NotifikasiStore/SET_MODAL_FCM'
      }),
      clearData() {
          $("#modal-FCM").modal('hide');
          this.changeShowModal(false)
      },
    },
    async mounted() {
       var vx = this;
      $("#modal-FCM").modal();
       await this.getByIdFCM(this.idFcm)
  
    }
  }
  </script>
  